:root{
	--second-color:#{$light-blue};
	--first-color:#{$dark-blue};
	--font:#{$font};
}

#root {
	font-family: var(--font,"Montserrat");
	box-sizing: border-box;
	//font-size: 62.5%; // 1rem = 10px
	scroll-behavior: smooth;
  
	@include responsive(tab-land) {
		//font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%;
	}
  
	@include responsive(tab-port) {
		//font-size: 50%; // 1rem = 8px, 8/16 = 50%;
	}


	* 
	*::after,
	*::before {
		margin: 0;
		padding: 0;
		box-sizing: inherit;
	}
	/*
	body {
		padding:0;
		box-sizing: border-box;
		margin:0;
	}*/

	h1, h2, h3, h4, h5, h6, p, ul, figure {
		margin: 0;
		padding: 0;
	}

	p {
		margin:0 0 0.5rem;

	}
	ul, li {
		list-style: none;

	}

	label {
		margin: 0!important;
	}

	@for $i from 0 through 100 {
		.width-percentage-#{$i} {
			width: #{$i}#{"%"} !important;
		}
	}

	.u-margin-top-small { margin-top: 1.5rem; }
	.u-margin-top-medium { margin-top: 4rem; }
	.u-margin-top-big { margin-top: 8rem; }
	.u-margin-top-huge { margin-top: 10rem; }
	.u-margin-bottom-small { margin-bottom: 1.5rem; }
	.u-margin-bottom-medium { margin-bottom: 4rem; }
	.u-margin-bottom-big { margin-bottom: 8rem; }
	.u-margin-bottom-huge { margin-bottom: 10rem; }
}