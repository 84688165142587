#root {

	.form {

		&__default {
			input[type="text"],
			input[type="email"],
			input[type="number"] {
				width: 13rem;
				height: 2rem;
				padding:0 0.8rem;
				margin:0 0 2rem !important;
				border: 1px solid $light-grey;
				color: $light-grey;

				&:active
				&:focus,
				&:focus-visible {
					outline: none !important;
				}

				@include responsive(tab-port) {
				   width: 100%;
				}
			}
		}

		&__order {
			input[type="textarea"]{
				width: 20em;
				height: 3.8rem;
				border: none;
				color: $dark-grey;
				font-size: $text;
				margin: 0 !important;

				&:active
				&:focus,
				&:focus-visible {
					outline: none !important;
				}
		
				&::placeholder {
					font-size: $text;
					color: $dark-grey;
				}
			}
		}
	}

	input[type="radio"]{
		width: 1.8rem;
		border: 2px solid $light-grey;
		margin: 0;

		@include responsive(phone) {
			margin: 0 0.25rem 0 1rem;
		}
	}

	select {  
		width: 13rem;
		height: 2rem;
		padding:0 0.8rem;
		margin:0 0 2rem;
		border: 1px solid $light-grey;
		color: $light-grey;

		@include responsive(tab-port) {
			margin:0 0 2rem;
			width: 100%;
		}
	}

	.error {
		border: 1px $red solid!important;
	}
}