//color font
#root {
	.font-color {
		&__light-grey {
			color: $light-grey;
		}

		&__grey {
			color: $grey;
		}

		&__dark-grey {
			color: $dark-grey;
		}

		&__dark-blue {
			color: $dark-blue;
		}

		&__white {
			color: $white;
		}
	}

	//font weight

	.weight {
		&__default {
			font-weight: 400;
		}

		&__bold {
			font-weight: 700;
		}
	}

	//type font

	label,
	p,
	input,
	select {
		font-size: $text;
		color: $dark-grey;
		font-weight: 500;
	}

	::placeholder {
		font-size: $text;
	}

	select::after,
	input[type="text"] > 
	::placeholder{
		color: $light-grey;
	}

	.reactLink {
		color: $light-grey;
		font-size: $text;
		text-decoration: none;
		transition: all .5s;
		cursor: pointer;
		margin: 0 !important;

		&:hover {
			color: $dark-blue;
		}
	}
	.title-etap {
		font-size: $title;
		color: $light-grey;
		font-weight: 700;
		text-transform: uppercase;

		
		@include responsive(phone) {
			font-size: 0.983rem;
		}

	}

	.title {
		font-size: $title;
		color: var(--first-color,blue);
		font-weight: 700;
		text-transform: uppercase;
		position: relative;
		padding-left: 55px;
		margin-bottom: 2rem;

		
		@include responsive(phone) {
			font-size: 0.983rem;
			padding-left: 0px;
		}

	}

	.title::before {
		content: "";
		position: absolute;
		display: inline;
		width: 40px;
		height: 15px;
		background-color: var(--first-color,blue);
		left: 0;
		top: 4px;

		@include responsive(phone) {
			display: none;
		}
	}

	.étoile{
		font-size: 1.25rem;
		font-weight: 700;
		color: $light-blue;
		line-height: 0;
	}
	.étoile-obligatoire::after{
		content:"*";
		font-size: 1.25rem;
		font-weight: 700;
		color: $light-blue;
		line-height: 0;
	}
}