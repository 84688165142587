@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

/*
$breakpoint argument choices
- small-phone
- phone
- tab-port
- tab-land
- big-desktop
*/

@mixin responsive($breakpoint) {
	@if $breakpoint == small-phone {
		@media only screen and (max-width: 30em) { @content }; //440px
	}
	@if $breakpoint == phone {
		@media only screen and (max-width: 43.75em) { @content }; //700px
	}
	@if $breakpoint == tab-port {
		@media only screen and (max-width: 56.25em) { @content }; //900px
	}
	@if $breakpoint == tab-land {
		@media only screen and (max-width: 75em) { @content }; //1200px
	}
	@if $breakpoint == big-desktop {
		@media only screen and (min-width: 112.5em) { @content }; //1800px+
	}

}