.form {
	width: 100%;
	max-width: 1110px;
	margin-bottom: 20rem;
	margin:auto;
	background-color: white;
}

.d-none{
	display:none;
}

dl {
	dd{
		font-size: 2em;
		font-weight: bold;
		color:var(--first-color,blue);
	}

	dt{
		font-size:1.5em;
	}
}