.container {
	width: 100%;
	padding: 0;

	@include responsive(tab-port) {
		margin: 0 auto;
	}

	&__wrapper {
		width: 55%;
		display: flex;

		@include responsive(tab-port) {
			width: 65%;
			flex-wrap: wrap;
		}

		@include responsive(phone) {
			width: 100%;
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__center {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__margin-left {
		margin-left:3rem;

		
		@include responsive(tab-land) {
			margin-left: 1rem;
		}

		@include responsive(tab-port) {
			margin-left: 0;
		}
	}

	&__margin-right {
		margin-right: 12%;

		@include responsive(tab-port) {
			margin-right: 18%;
		}

		@include responsive(phone) {
			margin-right: 0;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);


		@include responsive(phone) {
			grid-template-columns: repeat(1, 1fr);
			justify-content: center;
		}
	}

	&__grid-infos {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		@include responsive(phone) {
			grid-template-columns: repeat(1, 1fr);
			width: 100%;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		justify-content: end;
		margin: 0 0.75rem 0 0;
		width: 100%;

		@include responsive(phone) {
			margin: 1rem auto 0;
		}
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__row-baseline {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		margin: 0rem 0 0.5rem;

		@include responsive(phone) {
			margin: 0 auto;
			align-items: center;
		}
	}

	&__row-col {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include responsive(phone) {
			flex-direction: column;
			text-align: center;
		}
	}
}