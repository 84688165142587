.btn {
	font-size: $button;
	font-weight: 600;
	text-transform: uppercase;
	color: $white;
	background: var(--first-color,blue);
	cursor: pointer;
	text-align: center;
	border-radius: 0;
	margin: 0 0.5em;
	padding: 1em 2em;
	display: inline-block;
	transition: all .6s ease-in-out;

	&:hover,
	&:active,
	&:focus {
		color: var(--first-color,blue);
		background: var(--second-color,lightblue);
	}

	@include responsive(phone) {
		font-size: $text;
		margin: 0 0.25em;
		padding: 0.5em;
	}
}

.containe {
    width: 100%;
    display: flex;
    justify-content: center;
    
    &__position {
        position: absolute;
        margin: 2em auto;
    }

	&__end {
		position: absolute;
		margin: 2em 20%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0.5em;
		align-items: center;
	}
}

.containe__table{
    display:flex;
    justify-content: center;
    position: absolute;
	margin: 2em auto;
    
    table tr td div {
        margin: 0.125rem;
        display: flex;
        justify-content: center;
		
        @media only screen and (max-width: 700px) {
            margin: 0rem;
            width: auto;
        }
        
     }
}


