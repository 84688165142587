// color
$light-grey: #a7a9ac;
$grey: #343a40;
$dark-grey: #343a40;
$dark-blue: #003866;
$light-blue: #268aed;
$black: #000;
$white: #fff;
$red: #ff0000;
$font: "Montserrat";
$result-gradiant: linear-gradient(90deg, #ff6600, #fbb700, #5fcf0c, #346c0b);

//Font-Size
$title: 1.250rem;
$result-title: 1.2rem;
$price: 1.2rem;
$text:0.875rem;
$button: 1em;
$small: 0.75rem;
 